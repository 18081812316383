import React, { useState, useEffect, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultContext = {
  menuIsOpen: false,
  setMenuIsOpen: () => {},
  headerIsShrink: false,
  setHeaderIsShrink: () => {},
  bodyLock: false,
  setBodyLock: () => {},
};

export const AppContext = createContext(defaultContext);

export const App = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [headerIsShrink, setHeaderIsShrink] = useState(false);
  const [bodyLock, setBodyLock] = useState(false);

  useEffect(() => {
    if (menuIsOpen) {
      setBodyLock(true);
    } else {
      setBodyLock(false);
    }
  }, [menuIsOpen]);

  // Material UI theme
  const theme = createTheme({
    typography: {
      fontFamily: ['"Helvetica Neue"', 'Roboto', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: '#2964F6',
        dark: '#16215A',
      },
      error: {
        main: '#E20000',
      },
    },
  });

  const value = useMemo(
    () => ({
      menuIsOpen,
      setMenuIsOpen,
      headerIsShrink,
      setHeaderIsShrink,
      bodyLock,
      setBodyLock,
    }),
    [menuIsOpen, headerIsShrink, bodyLock]
  );

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={value}>{children}</AppContext.Provider>
    </ThemeProvider>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default {
  AppContext,
  App,
};
