exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-index-js": () => import("./../../../src/pages/academy/index.js" /* webpackChunkName: "component---src-pages-academy-index-js" */),
  "component---src-pages-academy-nz-js": () => import("./../../../src/pages/academy/nz.js" /* webpackChunkName: "component---src-pages-academy-nz-js" */),
  "component---src-pages-book-a-demo-js": () => import("./../../../src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-showroomnext-js": () => import("./../../../src/pages/showroomnext.js" /* webpackChunkName: "component---src-pages-showroomnext-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-builder-js": () => import("./../../../src/templates/builder.js" /* webpackChunkName: "component---src-templates-builder-js" */),
  "component---src-templates-learning-centre-video-js": () => import("./../../../src/templates/learning-centre-video.js" /* webpackChunkName: "component---src-templates-learning-centre-video-js" */),
  "component---src-templates-module-overview-js": () => import("./../../../src/templates/module-overview.js" /* webpackChunkName: "component---src-templates-module-overview-js" */),
  "component---src-templates-standard-js": () => import("./../../../src/templates/standard.js" /* webpackChunkName: "component---src-templates-standard-js" */)
}

