/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';

import './src/styles/global.css';
import 'plyr-react/dist/plyr.css';
import 'glider-js/glider.min.css';

// Components
import { App } from './src/components/app';

export const wrapPageElement = ({ element, props }) => {
  return <App {...props}>{element}</App>;
};
